import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import ToggleDrawerDialog from 'components/customDrawer/ToggleDrawerDialog';
import CustomInputField from 'components/customFields/CustomInputField';
import CustomSelectField from 'components/customSelect/CustomSelectField';
import LocationKey from 'components/locationKey';
import { monthNames } from 'constants/constants';
import { DownloadIcon } from 'helpers/images';
import moment from 'moment';
import SearchInputTextField from 'pages/FollowingTimeline/Preferences/SearchInput';
import React, { useState } from 'react';
import theme from 'theme';

import AddPodcastProvider from './AddPodcastProvider';

export interface IFilters {
  range: { to: string; from: string };
  searchQuery: string;
}

interface IProps {
  handleClearSearchField?: () => void;
  onDownloadCSV?: (filters: IFilters) => void;
  onChangeFilter?: (filters: IFilters) => void;
  searchQuery?: string;
  isDownloadCSV?: boolean;
  showDateRange?: boolean;
  csvLoader?: boolean;
  showSearch?: boolean;
  isDisabled?: boolean;
  isPayoutTab?: boolean;
  searchPlaceHolder?: string;
  showLocationKeyButton?: boolean;
}

// const PROVIDER_ADD_BUTTON_TITLES: any = {
//   businesses: "Add Business Provider",
//   news: "Add News Provider",
//   podcasts: "Add Podcasts Provider",
// };

const DEFAULT_RANGE = {
  from: '',
  to: '',
};

function DateRange(props: IProps) {
  const {
    isDownloadCSV,
    showDateRange,
    onDownloadCSV,
    onChangeFilter,
    csvLoader,
    showSearch,
    isDisabled,
    isPayoutTab,
    searchPlaceHolder,
    showLocationKeyButton,
  } = props;

  const [range, setRange] = useState(DEFAULT_RANGE);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showAddProviderModal, setShowAddProviderModal] = useState<boolean>(false);
  const [showLocationKeyModal, setShowLocationKeyModal] = useState<boolean>(false);

  const [payoutDate, setPayoutDate] = useState({ month: '', year: '' });

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileS = useMediaQuery(theme.breakpoints.down('midxs'));
  const currentYear = moment().year().toString();
  const currentMonth = (moment().month() + 1).toString();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    setSearchQuery(value);
    onChangeFilter?.({ range, searchQuery: value });
  };

  const handleClearSearchField = () => {
    setSearchQuery('');
    onChangeFilter?.({
      range: { to: range.to, from: range.from },
      searchQuery: '',
    });
  };

  const handleDateRangeChange = (type: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'startDate') {
      setRange({
        ...range,
        from: moment(e.target.value).format('YYYY-MM-DD'),
      });
    } else {
      setRange({
        ...range,
        to: moment(e.target.value).format('YYYY-MM-DD'),
      });
    }
  };

  const isDateRangeValid = () => {
    if (moment(range.from, 'YYYY-MM-DD').isValid() && moment(range.to, 'YYYY-MM-DD').isValid()) {
      const from = moment(range.from);
      const to = moment(range.to);
      return !from.isSameOrBefore(to);
    }
    if (
      !moment(range.from, 'YYYY-MM-DD').isValid() &&
      !moment(range.to, 'YYYY-MM-DD').isValid() &&
      (range?.from as string)?.length > 0 &&
      (range?.to as string)?.length > 0
    ) {
      return false;
    }
    return true;
  };

  const handleApplyDateRange = () => {
    const from = moment(range.from, 'YYYY-MM-DD').isValid() ? range.from : '';
    const to = moment(range.to, 'YYYY-MM-DD').isValid() ? range.to : '';
    onChangeFilter?.({ range: { from, to }, searchQuery });
  };

  const handlePayoutMonthChange =
    (dateType: string) => (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      if (dateType === 'year') {
        const payoutMonth =
          value === currentYear && Number(payoutDate?.month) >= Number(currentMonth)
            ? (Number(currentMonth) - 1).toString()
            : payoutDate?.month;
        const fullDate = `${value}-${payoutMonth}`;
        setPayoutDate({
          ...payoutDate,
          year: value,
          month: payoutMonth,
        });
        setRange({
          ...range,
          from: moment(fullDate).startOf('month').format('YYYY-MM-DD'),
          to: moment(fullDate).endOf('month').format('YYYY-MM-DD'),
        });
      } else {
        setPayoutDate({ ...payoutDate, month: value });
        const fullDate = `${payoutDate.year}-${value}`;
        setRange({
          ...range,
          from: moment(fullDate).startOf('month').format('YYYY-MM-DD'),
          to: moment(fullDate).endOf('month').format('YYYY-MM-DD'),
        });
      }
    };

  const downloadCSVHandler = () => onDownloadCSV?.({ range, searchQuery });

  return (
    <>
      <Box marginTop={theme.spacing(4)} width="100%">
        <Stack
          alignItems="center"
          direction={isMobile ? 'column' : 'row'}
          justifyContent={showDateRange ? 'space-between' : 'flex-end'}
          spacing={theme.spacing(2)}
          width="100%"
        >
          {showDateRange ? (
            <Stack
              direction={isMobileS ? 'column' : 'row'}
              spacing={theme.spacing(1.5)}
              width={isMobile ? '100%' : undefined}
            >
              <Stack
                direction={isMobileS ? 'column' : 'row'}
                spacing={theme.spacing(1.5)}
                width={isMobile ? '100%' : 'auto'}
              >
                <Stack
                  direction={isMobileS ? 'column' : 'row'}
                  spacing={theme.spacing(1.5)}
                  width="100%"
                >
                  {isPayoutTab ? (
                    <>
                      <CustomSelectField
                        fullWidth
                        inputProps={{
                          name: 'payoutYear',
                        }}
                        label=""
                        value={payoutDate.year}
                        // onBlur={handleBlur}
                        onChange={handlePayoutMonthChange('year')}
                      >
                        {Array(16)
                          .fill('')
                          .map((_, index) => (
                            <option
                              key={`expiryYear-${index - 1}`}
                              value={parseInt(currentYear, 10) - index}
                            >
                              {parseInt(currentYear, 10) - index}
                            </option>
                          ))}
                      </CustomSelectField>

                      <CustomSelectField
                        fullWidth
                        inputProps={{
                          name: 'payoutMonth',
                        }}
                        label=""
                        sx={{
                          '.MuiNativeSelect-select': {
                            width: 'auto',
                          },
                        }}
                        // onBlur={handleBlur}
                        value={payoutDate.month}
                        onChange={handlePayoutMonthChange('month')}
                      >
                        {monthNames.map((mName, index) => {
                          const month = (index + 1).toString().padStart(2, '0');
                          return (
                            <option
                              key={`payoutMonth-${index + 1}`}
                              disabled={
                                currentYear === payoutDate.year &&
                                Number(currentMonth) <= Number(month)
                              }
                              value={month}
                            >
                              {mName}
                            </option>
                          );
                        })}
                      </CustomSelectField>
                    </>
                  ) : (
                    <>
                      <CustomInputField
                        required
                        fullWidth={!!isMobile}
                        inputProps={{
                          max: moment().format('YYYY-MM-DD'),
                        }}
                        sx={{
                          borderColor: `${theme.palette.text.tertiary} !important`,
                        }}
                        type="date"
                        value={range.from}
                        onChange={handleDateRangeChange('startDate')}
                      />
                      <CustomInputField
                        fullWidth={!!isMobile}
                        inputProps={{
                          max: moment().format('YYYY-MM-DD'),
                        }}
                        sx={{
                          borderColor: `${theme.palette.text.tertiary} !important`,
                        }}
                        type="date"
                        value={range.to}
                        onChange={handleDateRangeChange('endDate')}
                      />
                    </>
                  )}
                </Stack>
                <Button
                  disabled={isDateRangeValid()}
                  size="large"
                  variant="contained"
                  onClick={handleApplyDateRange}
                >
                  Apply
                </Button>
              </Stack>
            </Stack>
          ) : null}
          <Stack
            alignItems="center"
            direction={isMobileS ? 'column' : 'row'}
            flexGrow={1}
            justifyContent="flex-end"
            spacing={theme.spacing(1.5)}
          >
            {showSearch ? (
              <Stack boxSizing="border-box" width={isMobile ? '100%' : undefined}>
                <Box
                  border="1px solid black"
                  borderColor={theme.palette.text.tertiary}
                  borderRadius={theme.spacing(0.5)}
                  boxSizing="border-box"
                  height={theme.spacing(5)}
                  width="100%"
                >
                  <SearchInputTextField
                    placeholder={`Search ${searchPlaceHolder}`}
                    sx={{ border: '1px solid black' }}
                    value={searchQuery}
                    onChange={handleInputChange}
                    onClear={handleClearSearchField}
                  />
                </Box>
              </Stack>
            ) : null}
            {isDownloadCSV ? (
              <LoadingButton
                disabled={isDisabled}
                fullWidth={!!isMobile}
                loading={csvLoader}
                size="large"
                startIcon={
                  <Box marginRight={theme.spacing(1)}>
                    <DownloadIcon />
                  </Box>
                }
                sx={{
                  color: theme.palette.text.secondary,
                  borderColor: theme.palette.text.tertiary,
                }}
                variant="outlined"
                onClick={() => downloadCSVHandler()}
              >
                Download CSV
              </LoadingButton>
            ) : null}
            {showLocationKeyButton && (
              <Button
                disableRipple
                disableTouchRipple
                fullWidth={!!isMobile}
                size="large"
                sx={{
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={() => setShowLocationKeyModal(true)}
              >
                Location Key
              </Button>
            )}
          </Stack>
        </Stack>
      </Box>

      {showAddProviderModal && (
        <ToggleDrawerDialog
          showCloseIcon
          handleClose={() => setShowAddProviderModal(false)}
          isMobile={isMobile}
          open={showAddProviderModal}
          setOpen={setShowAddProviderModal}
          title="Add Podcast Provider"
        >
          <AddPodcastProvider closeModal={() => setShowAddProviderModal(false)} />
        </ToggleDrawerDialog>
      )}
      {showLocationKeyModal && (
        <ToggleDrawerDialog
          showCloseIcon
          handleClose={() => setShowLocationKeyModal(false)}
          isMobile={isMobile}
          open={showLocationKeyModal}
          setOpen={setShowLocationKeyModal}
          title="Location Key"
        >
          <LocationKey />
        </ToggleDrawerDialog>
      )}
    </>
  );
}

export default DateRange;
