import cloneDeep from 'clone-deep';
import moment from 'moment';
import { Reducer } from 'redux';
import { IPreferencePodcast, IProvider } from 'store';
import { IBusiness, IBusinessDetails } from 'store/business/BusinessInterface';

import {
  IAffiliateData,
  IAffiliatePayoutData,
  IAllSponsorsReport,
  IApprovedBusiness,
  IDateRange,
  IInfluencer,
  IMembers,
  ISponsor,
  ISponsorAd,
  IUnApprovedBusinessState,
  IVettingDashboardState,
  VettingDashboardActions,
} from './VettingDashboardInterface';
import VettingDashboardType from './VettingDashboardTypes';

export const initialState: IVettingDashboardState = {
  loading: false,
  isGetBusinessesLoading: false,
  isListAffiliatesLoading: false,
  dateRange: { from: undefined, to: undefined } as IDateRange,
  affiliatesData: {
    offset: 0,
    hasMoreData: true,
    affiliates: [] as IAffiliateData[],
  },
  unApprovedBusinesses: {
    offset: 0,
    hasMoreData: true,
    unApprovedBusinesses: [] as IUnApprovedBusinessState[],
  },
  affiliatesPayoutData: {
    offset: 0,
    hasMoreData: true,
    affiliatesPayout: [] as IAffiliatePayoutData[],
  },
  AllSponsorsReportData: {
    offset: 0,
    hasMoreData: true,
    AllSponsorsReport: [] as IAllSponsorsReport[],
  },
  getAllSponsorsReportLoading: false,
  businessDetails: {} as IBusinessDetails,
  influencers: [] as IInfluencer[],
  influencersLoading: false,
  approvedBusinessesData: {
    offset: 0,
    hasMoreData: true,
    approvedBusinesses: [] as IApprovedBusiness[],
  },
  isApprovedBusinessesLoading: false,
  selectedProviderType: '',
  addProviderLoading: false,
  allBusinessLoading: false,
  allBusinesses: [] as IBusiness[],
  allNewsProvidersLoading: false,
  allNewsProviders: [] as IProvider[],
  allPodcastsLoading: false,
  allPodcasts: [] as IPreferencePodcast[],
  featuredProvidersLoading: false,
  addAdsLoading: false,
  sponsorsData: {
    offset: 0,
    hasMoreData: true,
    sponsors: [] as ISponsor[],
  },
  getSponsorsDataLoading: false,
  addSponsorLoading: false,
  sponsorAds: [] as ISponsorAd[],
  deleteSponsorLoading: false,
  deleteSponsorAdLoading: false,
  isMembersLoading: false,
  membersData: {
    offset: 0,
    hasMoreData: true,
    members: [],
  },
  addBusinessLoading: false,
};

const VettingDashboardReducer: Reducer<IVettingDashboardState, VettingDashboardActions> = (
  state = initialState,
  action = {} as VettingDashboardActions,
) => {
  switch (action.type) {
    case VettingDashboardType.RESET_DASHBOARD_STATE: {
      return {
        ...state,
        unApprovedBusinesses: {
          offset: 0,
          hasMoreData: true,
          unApprovedBusinesses: [] as IUnApprovedBusinessState[],
        },
      };
    }
    case VettingDashboardType.GET_UNAPPROVED_BUSINESSES_REQUEST: {
      return {
        ...state,
        isGetBusinessesLoading: true,
      };
    }
    case VettingDashboardType.GET_UNAPPROVED_BUSINESSES_SUCCESS: {
      return {
        ...state,
        isGetBusinessesLoading: false,
        unApprovedBusinesses: {
          offset: action.payload.offset,
          hasMoreData: action.payload.hasMoreData,
          unApprovedBusinesses: [
            ...state.unApprovedBusinesses.unApprovedBusinesses,
            ...action.payload.unApprovedBusinesses,
          ],
        },
      };
    }
    case VettingDashboardType.GET_UNAPPROVED_BUSINESSES_ERROR: {
      return {
        ...state,
        isGetBusinessesLoading: false,
      };
    }
    case VettingDashboardType.GET_BUSINESS_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case VettingDashboardType.GET_BUSINESS_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        businessDetails: action.payload,
      };
    }
    case VettingDashboardType.GET_BUSINESS_DETAILS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case VettingDashboardType.DELETE_BUSINESS_ADMIN_REQUEST: {
      return {
        ...state,
      };
    }
    case VettingDashboardType.DELETE_BUSINESS_ADMIN_SUCCESS: {
      const approvedBusinesses = cloneDeep(state.approvedBusinessesData.approvedBusinesses);
      const selectedIndex = approvedBusinesses.findIndex(
        business => business.documentId === action.payload,
      );
      approvedBusinesses.splice(selectedIndex, 1);
      return {
        ...state,
        approvedBusinessesData: {
          ...state.approvedBusinessesData,
          approvedBusinesses,
        },
      };
    }
    case VettingDashboardType.DELETE_BUSINESS_ADMIN_ERROR: {
      return {
        ...state,
      };
    }

    case VettingDashboardType.DENY_BUSINESS_REQUEST:
    case VettingDashboardType.APPROVE_BUSINESS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case VettingDashboardType.DENY_BUSINESS_SUCCESS:
    case VettingDashboardType.APPROVE_BUSINESS_SUCCESS: {
      const approvedBusinesses = cloneDeep(state.approvedBusinessesData.approvedBusinesses);
      const selectedIndex = approvedBusinesses.findIndex(
        business => business.documentId === action.payload,
      );
      approvedBusinesses.splice(selectedIndex, 1);
      return {
        ...state,
        loading: false,
        approvedBusinessesData: {
          ...state.approvedBusinessesData,
          approvedBusinesses,
        },
      };
    }
    case VettingDashboardType.DENY_BUSINESS_ERROR:
    case VettingDashboardType.APPROVE_BUSINESS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case VettingDashboardType.SEND_EMAIL_TO_BUSINESS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case VettingDashboardType.SEND_EMAIL_TO_BUSINESS_ERROR:
    case VettingDashboardType.SEND_EMAIL_TO_BUSINESS_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case VettingDashboardType.SET_DATE_RANGE: {
      return {
        ...state,
        dateRange: { from: action.payload.from, to: action.payload.to },
        ...(action.payload.tabIndex === 1 && {
          affiliatesData: {
            offset: 0,
            hasMoreData: true,
            affiliates: [] as IAffiliateData[],
          },
        }),
        ...(action.payload.tabIndex === 2 && {
          affiliatesPayoutData: {
            affiliatesPayout: [] as IAffiliatePayoutData[],
            hasMoreData: true,
            initialLoad: true,
            offset: 0,
          },
        }),
        ...(action.payload.tabIndex === 3 && {
          AllSponsorsReportData: {
            offset: 0,
            hasMoreData: true,
            AllSponsorsReport: [] as IAllSponsorsReport[],
          },
        }),
        ...(action.payload.tabIndex === 4 && {
          sponsorsData: {
            offset: 0,
            hasMoreData: true,
            sponsors: [] as ISponsor[],
            initialLoad: true,
          },
        }),
        ...(action.payload.tabIndex === 0 && {
          approvedBusinessesData: {
            offset: 0,
            hasMoreData: true,
            approvedBusinesses: [] as IApprovedBusiness[],
          },
        }),
      };
    }
    case VettingDashboardType.LIST_AFFILIATES_REQUEST: {
      return {
        ...state,
        isListAffiliatesLoading: true,
      };
    }
    case VettingDashboardType.LIST_AFFILIATES_SUCCESS: {
      return {
        ...state,
        isListAffiliatesLoading: false,
        affiliatesData: {
          offset: action.payload.offset,
          hasMoreData: action.payload.hasMoreData,
          affiliates: [...state.affiliatesData.affiliates, ...action.payload.affiliates],
        },
      };
    }
    case VettingDashboardType.LIST_AFFILIATES_ERROR: {
      return {
        ...state,
        isListAffiliatesLoading: false,
      };
    }
    case VettingDashboardType.RESET_AFFLIATES_LIST_STATE: {
      return {
        ...state,
        affiliatesData: {
          offset: 0,
          hasMoreData: true,
          affiliates: [] as IAffiliateData[],
        },
      };
    }
    case VettingDashboardType.RESET_BUSINESS_DETAILS_STATE: {
      return {
        ...state,
        businessDetails: {} as IBusinessDetails,
      };
    }
    case VettingDashboardType.GET_INFLUENCERS_REQUEST: {
      return {
        ...state,
        influencersLoading: true,
      };
    }
    case VettingDashboardType.GET_INFLUENCERS_SUCCESS: {
      return {
        ...state,
        influencersLoading: false,
        influencers: action.payload,
      };
    }
    case VettingDashboardType.GET_INFLUENCERS_ERROR: {
      return {
        ...state,
        influencersLoading: false,
      };
    }
    case VettingDashboardType.RESET_AFFILIATES_PAYOUT_STATE: {
      return {
        ...state,
        affiliatesPayoutData: {
          offset: 0,
          hasMoreData: true,
          affiliatesPayout: [] as IAffiliatePayoutData[],
          initialLoad: true,
        },
      };
    }
    case VettingDashboardType.AFFILIATES_PAYOUT_REQUEST: {
      return {
        ...state,
        isListAffiliatesLoading: true,
      };
    }
    case VettingDashboardType.AFFILIATES_PAYOUT_SUCCESS: {
      return {
        ...state,
        isListAffiliatesLoading: false,
        affiliatesPayoutData: {
          offset: action.payload.offset,
          hasMoreData: action.payload.hasMoreData,
          affiliatesPayout: action.payload.initialLoad
            ? [...action.payload.affiliatesPayout]
            : [...state.affiliatesPayoutData.affiliatesPayout, ...action.payload.affiliatesPayout],
          initialLoad: action.payload.initialLoad,
        },
      };
    }
    case VettingDashboardType.AFFILIATES_PAYOUT_ERROR: {
      return {
        ...state,
        isListAffiliatesLoading: false,
      };
    }
    case VettingDashboardType.CREATE_PAYOUT_REQUEST: {
      return {
        ...state,
      };
    }
    case VettingDashboardType.CREATE_PAYOUT_SUCCESS: {
      const { affiliatesPayout } = state.affiliatesPayoutData;
      affiliatesPayout[action.payload.index].type = 'paid';
      affiliatesPayout[action.payload.index].documentId = action.payload.documentId;

      return {
        ...state,
        affiliatesPayoutData: {
          ...state.affiliatesPayoutData,
          affiliatesPayout: [...affiliatesPayout],
        },
      };
    }
    case VettingDashboardType.CREATE_PAYOUT_ERROR: {
      return {
        ...state,
      };
    }
    case VettingDashboardType.GET_APPROVED_BUSINESSES_REQUEST: {
      return {
        ...state,
        isApprovedBusinessesLoading: true,
      };
    }
    case VettingDashboardType.GET_APPROVED_BUSINESSES_SUCCESS: {
      const { offset, hasMoreData, reset, approvedBusinesses } = action.payload;

      let updatedData = [...approvedBusinesses];
      if (!reset) {
        updatedData = [
          ...state.approvedBusinessesData.approvedBusinesses,
          ...action.payload.approvedBusinesses,
        ];
      }

      return {
        ...state,
        isApprovedBusinessesLoading: false,
        approvedBusinessesData: {
          offset,
          hasMoreData,
          approvedBusinesses: updatedData,
        },
      };
    }
    case VettingDashboardType.GET_APPROVED_BUSINESSES_ERROR: {
      return {
        ...state,
        isApprovedBusinessesLoading: false,
      };
    }
    case VettingDashboardType.RESET_APPROVED_BUSINESSES_STATE: {
      return {
        ...state,
        approvedBusinessesData: {
          offset: 0,
          hasMoreData: true,
          approvedBusinesses: [] as IApprovedBusiness[],
        },
      };
    }

    case VettingDashboardType.GET_MEMBERS_REQUEST: {
      return {
        ...state,
        isMembersLoading: true,
      };
    }
    case VettingDashboardType.GET_MEMBERS_SUCCESS: {
      const { offset, hasMoreData, reset, members } = action.payload;

      let updatedData = [...members];
      if (!reset) {
        updatedData = [...state.membersData.members, ...action.payload.members];
      }

      return {
        ...state,
        isMembersLoading: false,
        membersData: {
          offset,
          hasMoreData,
          members: updatedData,
        },
      };
    }
    case VettingDashboardType.GET_MEMBERS_ERROR: {
      return {
        ...state,
        isMembersLoading: false,
      };
    }

    case VettingDashboardType.DELETE_USER_ADMIN_REQUEST: {
      return {
        ...state,
      };
    }

    case VettingDashboardType.DELETE_USER_ADMIN_SUCCESS: {
      const allMembers = cloneDeep(state.membersData.members);
      const selectedIndex = allMembers.findIndex(member => member.documentId === action.payload);
      allMembers.splice(selectedIndex, 1);

      return {
        ...state,
        membersData: {
          offset: state.membersData.offset,
          hasMoreData: state.membersData.hasMoreData,
          members: allMembers,
        },
      };
    }

    case VettingDashboardType.DELETE_USER_ADMIN_ERROR: {
      return {
        ...state,
      };
    }

    case VettingDashboardType.SET_TOWN_CRIER_ADMIN_REQUEST:
    case VettingDashboardType.SET_TOWN_CRIER_ADMIN_SUCCESS:
    case VettingDashboardType.SET_TOWN_CRIER_ADMIN_ERROR: {
      return {
        ...state,
      };
    }

    case VettingDashboardType.RESET_MEMBERS_STATE: {
      return {
        ...state,
        membersData: {
          offset: 0,
          hasMoreData: true,
          members: [] as IMembers[],
        },
      };
    }

    case VettingDashboardType.DELETE_PAYOUT_REQUEST: {
      return {
        ...state,
      };
    }
    case VettingDashboardType.DELETE_PAYOUT_SUCCESS: {
      const { affiliatesPayout } = state.affiliatesPayoutData;
      affiliatesPayout[action.payload].type = 'unpaid';
      return {
        ...state,
        affiliatesPayoutData: {
          ...state.affiliatesPayoutData,
          affiliatesPayout: [...affiliatesPayout],
        },
      };
    }
    case VettingDashboardType.DELETE_PAYOUT_ERROR: {
      return {
        ...state,
      };
    }
    case VettingDashboardType.SET_SELECTED_PROVIDER_TYPE: {
      return {
        ...state,
        selectedProviderType: action.payload,
      };
    }
    case VettingDashboardType.ADD_PODCAST_PROVIDER_REQUEST: {
      return {
        ...state,
        addProviderLoading: true,
      };
    }
    case VettingDashboardType.ADD_PODCAST_PROVIDER_SUCCESS: {
      return {
        ...state,
        allPodcasts: [...state.allPodcasts, action.payload],
        addProviderLoading: false,
      };
    }
    case VettingDashboardType.ADD_PODCAST_PROVIDER_ERROR: {
      return {
        ...state,
        addProviderLoading: false,
      };
    }

    case VettingDashboardType.EDIT_PODCAST_REQUEST: {
      return {
        ...state,
        addProviderLoading: true,
      };
    }
    case VettingDashboardType.EDIT_PODCAST_SUCCESS: {
      const allPodcast = cloneDeep(state.allPodcasts);
      const selectedIndex = allPodcast.findIndex(
        podcast => podcast.documentId === action.payload.documentId,
      );

      allPodcast[selectedIndex] = action.payload;

      return {
        ...state,
        addProviderLoading: false,
        allPodcasts: allPodcast,
      };
    }
    case VettingDashboardType.EDIT_PODCAST_ERROR: {
      return {
        ...state,
        addProviderLoading: false,
      };
    }

    case VettingDashboardType.DELETE_PODCAST_REQUEST: {
      return {
        ...state,
        addProviderLoading: true,
      };
    }

    case VettingDashboardType.DELETE_PODCAST_SUCCESS: {
      const allPodcast = cloneDeep(state.allPodcasts);
      const selectedIndex = allPodcast.findIndex(podcast => podcast.documentId === action.payload);

      allPodcast.splice(selectedIndex, 1);

      return {
        ...state,
        addProviderLoading: false,
        allPodcasts: allPodcast,
      };
    }

    case VettingDashboardType.DELETE_PODCAST_ERROR: {
      return {
        ...state,
        addProviderLoading: false,
      };
    }

    case VettingDashboardType.GET_ALL_BUSINESSES_ADMIN_REQUEST: {
      return {
        ...state,
        allBusinessLoading: true,
      };
    }
    case VettingDashboardType.GET_ALL_BUSINESSES_ADMIN_SUCCESS: {
      return {
        ...state,
        allBusinessLoading: false,
        allBusinesses: action.payload,
      };
    }
    case VettingDashboardType.GET_ALL_BUSINESSES_ADMIN_ERROR: {
      return {
        ...state,
        allBusinessLoading: false,
      };
    }

    case VettingDashboardType.GET_ALL_NEWS_PROVIDERS_ADMIN_REQUEST: {
      return {
        ...state,
        allNewsProvidersLoading: true,
      };
    }
    case VettingDashboardType.GET_ALL_NEWS_PROVIDERS_ADMIN_SUCCESS: {
      return {
        ...state,
        allNewsProvidersLoading: false,
        allNewsProviders: action.payload,
      };
    }
    case VettingDashboardType.GET_ALL_NEWS_PROVIDERS_ADMIN_ERROR: {
      return {
        ...state,
        allNewsProvidersLoading: false,
      };
    }

    case VettingDashboardType.GET_ALL_PODCASTS_ADMIN_REQUEST: {
      return {
        ...state,
        allPodcastsLoading: true,
      };
    }
    case VettingDashboardType.GET_ALL_PODCASTS_ADMIN_SUCCESS: {
      return {
        ...state,
        allPodcastsLoading: false,
        allPodcasts: action.payload,
      };
    }
    case VettingDashboardType.GET_ALL_PODCASTS_ADMIN_ERROR: {
      return {
        ...state,
        allPodcastsLoading: false,
      };
    }
    case VettingDashboardType.SAVE_FEATURED_BUSINESS_REQUEST: {
      return {
        ...state,
        featuredProvidersLoading: true,
      };
    }
    case VettingDashboardType.SAVE_FEATURED_BUSINESS_SUCCESS: {
      const businesses = cloneDeep(state.allBusinesses);
      businesses.forEach(provider => {
        /* eslint-disable no-param-reassign */
        provider.isFeatured = action.payload.includes(provider.documentId);
        provider.dateFeatured = action.payload.includes(provider.documentId)
          ? moment().toString()
          : null;
      });

      return {
        ...state,
        featuredProvidersLoading: false,
        allBusinesses: [...businesses],
      };
    }
    case VettingDashboardType.SAVE_FEATURED_BUSINESS_ERROR: {
      return {
        ...state,
        featuredProvidersLoading: false,
      };
    }
    case VettingDashboardType.SAVE_FEATURED_PODCAST_PROVIDERS_REQUEST: {
      return {
        ...state,
        featuredProvidersLoading: true,
      };
    }
    case VettingDashboardType.SAVE_FEATURED_PODCAST_PROVIDERS_SUCCESS: {
      const podcastProviders = cloneDeep(state.allPodcasts);
      podcastProviders.forEach(provider => {
        /* eslint-disable no-param-reassign */
        provider.isFeatured = action.payload.includes(provider.documentId);
        provider.dateFeatured = action.payload.includes(provider.documentId)
          ? moment().toString()
          : null;
      });
      return {
        ...state,
        featuredProvidersLoading: false,
        allPodcasts: [...podcastProviders],
      };
    }
    case VettingDashboardType.SAVE_FEATURED_PODCAST_PROVIDERS_ERROR: {
      return {
        ...state,
        featuredProvidersLoading: false,
      };
    }
    case VettingDashboardType.SAVE_FEATURED_NEWS_PROVIDERS_REQUEST: {
      return {
        ...state,
        featuredProvidersLoading: true,
      };
    }
    case VettingDashboardType.SAVE_FEATURED_NEWS_PROVIDERS_SUCCESS: {
      const newsProviders = cloneDeep(state.allNewsProviders);
      newsProviders.forEach(provider => {
        /* eslint-disable no-param-reassign */
        provider.isFeatured = action.payload.includes(provider.documentID);
        provider.dateFeatured = action.payload.includes(provider.documentID)
          ? moment().toString()
          : null;
      });
      return {
        ...state,
        featuredProvidersLoading: false,
        allNewsProviders: [...newsProviders],
      };
    }
    case VettingDashboardType.SAVE_FEATURED_NEWS_PROVIDERS_ERROR: {
      return {
        ...state,
        featuredProvidersLoading: false,
      };
    }
    case VettingDashboardType.GET_ADS_REPORT_REQUEST: {
      return {
        ...state,
        getAllSponsorsReportLoading: true,
      };
    }
    case VettingDashboardType.GET_ADS_REPORT_SUCCESS: {
      const { offset, hasMoreData, reset, AllSponsorsReport } = action.payload;

      let updatedData = [...AllSponsorsReport];

      if (!reset) {
        updatedData = [...state.AllSponsorsReportData.AllSponsorsReport, ...AllSponsorsReport];
      }

      return {
        ...state,
        getAllSponsorsReportLoading: false,
        AllSponsorsReportData: {
          offset,
          hasMoreData,
          AllSponsorsReport: updatedData,
        },
      };
    }
    case VettingDashboardType.GET_ADS_REPORT_ERROR: {
      return {
        ...state,
        getAllSponsorsReportLoading: false,
      };
    }
    case VettingDashboardType.RESET_ADS_REPORT_STATE: {
      return {
        ...state,
        AllSponsorsReportData: {
          offset: 0,
          hasMoreData: true,
          AllSponsorsReport: [] as IAllSponsorsReport[],
        },
      };
    }
    case VettingDashboardType.CREATE_ADS_REQUEST: {
      return {
        ...state,
        addAdsLoading: true,
      };
    }
    case VettingDashboardType.CREATE_ADS_SUCCESS: {
      return {
        ...state,
        addAdsLoading: false,
        sponsorAds: [...state.sponsorAds, action.payload],
      };
    }
    case VettingDashboardType.CREATE_ADS_ERROR: {
      return {
        ...state,
        addAdsLoading: false,
      };
    }
    case VettingDashboardType.GET_SPONSORS_LIST_REQUEST: {
      return {
        ...state,
        getSponsorsDataLoading: true,
      };
    }
    case VettingDashboardType.GET_SPONSORS_LIST_SUCCESS: {
      const { offset, hasMoreData, initialLoad, sponsors } = action.payload;
      return {
        ...state,
        getSponsorsDataLoading: false,
        sponsorsData: {
          offset,
          hasMoreData,
          initialLoad,
          sponsors: initialLoad ? [...sponsors] : [...state.sponsorsData.sponsors, ...sponsors],
        },
      };
    }
    case VettingDashboardType.GET_SPONSORS_LIST_ERROR: {
      return {
        ...state,
        getSponsorsDataLoading: false,
      };
    }
    case VettingDashboardType.ADD_SPONSOR_REQUEST: {
      return {
        ...state,
        addSponsorLoading: true,
      };
    }
    case VettingDashboardType.ADD_SPONSOR_SUCCESS: {
      return {
        ...state,
        addSponsorLoading: false,
        sponsorsData: {
          ...state.sponsorsData,
          sponsors: [action.payload, ...state.sponsorsData.sponsors],
        },
      };
    }
    case VettingDashboardType.ADD_SPONSOR_ERROR: {
      return {
        ...state,
        addSponsorLoading: false,
      };
    }
    case VettingDashboardType.GET_ADS_BY_SPONSORID_REQUEST: {
      return {
        ...state,
        getAllSponsorsReportLoading: true,
        sponsorAds: [],
      };
    }
    case VettingDashboardType.GET_ADS_BY_SPONSORID_SUCCESS: {
      return {
        ...state,
        getAllSponsorsReportLoading: false,
        sponsorAds: action.payload || [],
      };
    }
    case VettingDashboardType.GET_ADS_BY_SPONSORID_ERROR: {
      return {
        ...state,
        getAllSponsorsReportLoading: false,
      };
    }
    case VettingDashboardType.RESET_SPONSOR_ADS_STATE: {
      return {
        ...state,
        sponsorsData: {
          offset: 0,
          hasMoreData: true,
          sponsors: [] as ISponsor[],
          initialLoad: true,
        },
        sponsorAds: [],
      };
    }
    case VettingDashboardType.DELETE_SPONSOR_REQUEST: {
      return {
        ...state,
        deleteSponsorLoading: true,
      };
    }
    case VettingDashboardType.DELETE_SPONSOR_SUCCESS: {
      const sponsors = cloneDeep(state.sponsorsData.sponsors);
      const selectedIndex = sponsors.findIndex(sponsor => sponsor.documentId === action.payload);
      sponsors.splice(selectedIndex, 1);
      return {
        ...state,
        deleteSponsorLoading: false,
        sponsorsData: {
          ...state.sponsorsData,
          sponsors,
        },
      };
    }
    case VettingDashboardType.DELETE_SPONSOR_ERROR: {
      return {
        ...state,
        deleteSponsorLoading: false,
      };
    }
    case VettingDashboardType.EDIT_SPONSOR_REQUEST: {
      return {
        ...state,
        addSponsorLoading: true,
      };
    }
    case VettingDashboardType.EDIT_SPONSOR_SUCCESS: {
      const sponsors = cloneDeep(state.sponsorsData.sponsors);
      const selectedIndex = sponsors.findIndex(
        sponsor => sponsor.documentId === action.payload.documentId,
      );
      sponsors[selectedIndex] = { ...action.payload };

      return {
        ...state,
        addSponsorLoading: false,
        sponsorsData: {
          ...state.sponsorsData,
          sponsors,
        },
      };
    }
    case VettingDashboardType.EDIT_SPONSOR_ERROR: {
      return {
        ...state,
        addSponsorLoading: false,
      };
    }
    case VettingDashboardType.DELETE_SPONSOR_AD_REQUEST: {
      return {
        ...state,
        deleteSponsorAdLoading: true,
      };
    }
    case VettingDashboardType.DELETE_SPONSOR_AD_SUCCESS:
    case VettingDashboardType.DELETE_SPONSOR_AD_ERROR: {
      return {
        ...state,
        deleteSponsorAdLoading: false,
      };
    }

    case VettingDashboardType.EDIT_SPONSOR_AD_REQUEST: {
      return {
        ...state,
        addAdsLoading: true,
      };
    }
    case VettingDashboardType.EDIT_SPONSOR_AD_SUCCESS: {
      const sponsorAds = cloneDeep(state.sponsorAds);
      const selectedIndex = sponsorAds.findIndex(
        sponsorAd => sponsorAd.documentId === action.payload.documentId,
      );
      if (selectedIndex >= 0) {
        sponsorAds[selectedIndex] = { ...action.payload };
      }

      const allSponsorsReportData = cloneDeep(state.AllSponsorsReportData.AllSponsorsReport);
      const selectedAllIndex = allSponsorsReportData.findIndex(
        sponsorAd => sponsorAd.documentId === action.payload.documentId,
      );

      if (selectedAllIndex >= 0) {
        allSponsorsReportData[selectedAllIndex].coupon = action.payload.coupon;
        allSponsorsReportData[selectedAllIndex].couponUrl = action.payload.couponUrl;
        allSponsorsReportData[selectedAllIndex].media = action.payload.media;
        allSponsorsReportData[selectedAllIndex].url = action.payload.url;
      }

      return {
        ...state,
        addAdsLoading: false,
        sponsorAds: [...sponsorAds],
        AllSponsorsReportData: {
          ...state.AllSponsorsReportData,
          AllSponsorsReport: allSponsorsReportData,
        },
      };
    }
    case VettingDashboardType.EDIT_SPONSOR_AD_ERROR: {
      return {
        ...state,
        addAdsLoading: false,
      };
    }

    case VettingDashboardType.CREATE_BUSINESS_ADMIN_REQUEST: {
      return {
        ...state,
        addBusinessLoading: true,
      };
    }
    case VettingDashboardType.CREATE_BUSINESS_ADMIN_SUCCESS: {
      return {
        ...state,
        addBusinessLoading: false,
      };
    }
    case VettingDashboardType.CREATE_BUSINESS_ADMIN_ERROR: {
      return {
        ...state,
        addBusinessLoading: false,
      };
    }

    default:
      return state;
  }
};

export default VettingDashboardReducer;
